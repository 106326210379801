import login from './login'
import pay from "./pay";
import Store from "../store";
import { TAG_TYPES } from '@/utils/const'
const formatDate = (time, showTime) => {
  const date = new Date(time * 1000);
  const YY = date.getFullYear() + '-';
  const MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  const DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
  const hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
  const mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
  const ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
  if (showTime) {
    return YY + MM + DD +" "+hh + mm + ss;
  }
  return YY + MM + DD
}
const fomtFileSize = (filesize) => {
  return `${(filesize / 1024).toFixed(2)}KB`
}
const formatQuality = (text) => {
  if (!text) return []
  // // console.log('text', text)
  return (text.split(',').map(item => {
    return TAG_TYPES[item]
  }))
}
export default {
  install(Vue) {
    Vue.prototype.user = Store.getters.user
    Vue.prototype.$imgurl = ''
    // Vue.prototype.$isLogin = Store.getters.isLogin
    // Vue.prototype.$isLogin = Store.getters.isLogin
    Vue.prototype.$pay = pay(Vue)
    Vue.prototype.$login = login(Vue)
    Vue.prototype.$formatDate = formatDate
    Vue.prototype.$fomtFileSize = fomtFileSize
    Vue.prototype.$formatQuality = formatQuality
  }
}
