<template>
    <div class="plug-pay" v-if="dialogFormVisible">
        <el-dialog
                title=""
                :visible.sync="dialogFormVisible"
                width="430"
                center
                :close-on-click-modal="false"
                :before-close="beforeClose"
        >
         <div v-if="displayType == 'pay'">
             <div v-if="options.type != 'recharge'">
                 <el-row ><span>共有{{downFiles.length}}个文件</span><span v-if="options.type == 'down'">,已选{{checks.length}}个文件</span></el-row>
                 <div style="max-height: 200px;overflow: auto">
                     <el-row  class="title-block" :key="index" v-for="(item, index) in archiveData[options.downType]">
                         <el-col v-if="options.type == 'down'" :span="2" style="display: flex;justify-content: center;align-items: center;height: 60px">
                             <el-checkbox v-model="item.check"></el-checkbox>
                         </el-col>
                         <el-col :span="22">
                             <div class="title">
                                 <i class="iconfont icon-pdf" v-if="item.imagetype === 'pdf'"></i>
                                  <i class="iconfont icon-wendangdocument84" v-else></i>

                               {{item.filename}}
                             </div>
                             <div class="size">
                                 <span>文件大小:</span>{{$fomtFileSize(item.filesize)}}
                             </div>
                         </el-col>
                     </el-row>
                 </div>

                 <el-row class="pay-type" v-if="!isPayed">
                     <el-tag @click="changePayType(item.type)" :type="paytype == item.type? item.active: 'info'" :key="index" v-for="(item, index) in payTypeList">
                         <div >
                             <i class="iconfont" :class="item.icon" :style="{fontSize: item.iconSize}"></i>
                             <span v-show="item.type == 'balance'">{{userData.money}}币</span>
                             <br/>
                             <span>{{item.text}}</span>
                         </div>
                     </el-tag>
                 </el-row>
                 <el-row v-if="paytype === 'balance'">
                     <el-form>
                         <el-input autocomplete="new-password" placeholder="请输出登录密码校验" @blur="inputBlur" show-password  v-model="password"></el-input>
                         <span v-show="pwdErr" style="color: red;font-size: 13px">密码错误</span>
                     </el-form>

                 </el-row>
                 <el-row style="text-align: center;margin-top: 15px">
                     <el-col :span="24">
                         <el-button  v-if="!isPayed" type="danger" style="width: 250px" @click="goPay">去支付(￥{{archiveData.price}}{{paytype === 'balance' ? '币' : '元'}})</el-button>
                         <el-button  v-else type="danger" style="width: 250px" @click="down">立即下载</el-button>
                     </el-col>
                     <el-col :span="24" style="margin-top: 5px;font-size: 12px">
                         <span>下载后即表示接受</span>
                         <el-tooltip class="item" effect="dark" placement="right">
                             <el-link type="primary" style="font-size: 12px">《下载须知》</el-link>
                             <div slot="content" style="width: 250px">
                                 <p v-for="(text, index) in downInstruc" :key="index">
                                     {{index + 1}}.{{text}}
                                 </p>
                             </div>
                         </el-tooltip>

                     </el-col>
                 </el-row>
             </div>
             <div v-else>
                 <el-row>
                     <div>请选择充值金额：</div>
                 </el-row>
                 <el-row class="recharge" :gutter="10" style="margin-top: 10px">
                     <el-col :span="7"  v-for="(money, index) in rechargeList" :key="index">
                         <el-tag @click="changeRechargeMoney(money)"  :type="rechargeMoney == money? 'danger' : 'info'" >
                             <div >
                                 <i class="iconfont icon-yue"></i>
                                 <span>{{money}}币</span>
                             </div>
                         </el-tag>
                     </el-col>
                     <el-col :span="7">
                         <el-input placeholder="其他金额" type="number" :min="1" v-model="rechargeInputMoney" @blur="changeRechargeMoney(-1)"/>
                     </el-col>

                 </el-row>
                 <el-row style="margin-top: 10px">
                     <div>请选择支付方式：</div>
                 </el-row>
                 <el-row class="pay-type" >
                     <el-tag @click="changePayType(item.type)" v-show="item.type != 'balance'" :type="paytype == item.type?  item.active: 'info'" :key="index" v-for="(item, index) in payTypeList">
                         <div >
                             <i class="iconfont" :class="item.icon" :style="{fontSize: item.iconSize}"></i>
                             <br/>
                             <span>{{item.text}}</span>
                         </div>
                     </el-tag>

                 </el-row>

                 <el-row style="text-align: center;margin-top: 15px">
                     <el-col :span="24" >
                         <el-button type="danger" style="width: 250px" @click="goPay">立即充值</el-button>
                     </el-col>
                 </el-row>
             </div>
         </div>
         <div v-if="displayType == 'qrcode'">
             <div  class="qrcode-pay">
                 <div class="card" :class="paytype">
                     <div class="pay-header">
                         请使用{{paytype === 'wechat' ? '微信' : '支付宝'}}扫一扫支付
                     </div>
                     <div class="qrcode" ref="qrCodeUrl">
<!--                         <img src="@/assets/images/code.png" alt="" style="width: 200px; height: 200px;">-->
                     </div>
                     <div class="m-10-0">
                         <span>需支付金额：<span style="color: red">{{orderData.amount}}元</span>  </span>
                     </div>
                     <el-row class="m-10-0">
                         <el-col :span="24" style="font-size: 12px">
                             <span>扫码即表示接受</span>
                             <el-tooltip class="item" effect="dark" placement="right">
                                 <el-link type="primary" style="font-size: 12px">《下载须知》</el-link>
                                 <div slot="content" style="width: 250px">
                                     <p v-for="(text, index) in downInstruc" :key="index">
                                         {{index + 1}}.{{text}}
                                     </p>
                                 </div>
                             </el-tooltip>
                         </el-col>
                     </el-row>
                 </div>
                 <div style="margin: 10px auto;text-align: center">
                     <el-button type="text" style="font-size: 12px;text-align: center;" @click="changeType">使用其他支付方式</el-button>
                 </div>
             </div>
         </div>
        </el-dialog>
        <el-dialog
                title=""
                :visible.sync="dialogPwdVisible"
                width="430"
                style="margin-top: 10%"
                center
                :close-on-click-modal="false"
        >
            <el-input autocomplete="new-password" placeholder="请输出新密码" show-password  v-model="password"></el-input>
        </el-dialog>
    </div>
</template>

<script>
    import {archivesService} from "../../api/archives";
    import {PayService} from "../../api/pay";
    // import {} from "../../api/user";
    import QRCode from 'qrcodejs2'
    // import { mapGetters } from 'vuex'
    import Store from '../../store'
    import {UserService} from "../../api/user";
    export default {
        name: "pay",
        data() {
            return {
                password: '',
                // displayType: 'qrcode',
                pwdErr: false,
                displayType: 'pay',
                userData: {},
                rechargeMoney: '',
                rechargeInputMoney: '',
                paytype: 'wechat',
                dialogFormVisible: false,
                isLogin: false,
                dialogPwdVisible: false,
                archiveData: {},
                options: {},
                downInstruc: [
                    '标准文档要求电子版与印刷版保持一致,所以下载的文档中可能包含空白页,非文档质量问题。',
                    '本网站所提供的标准文本仅供个人学习、研究之用,未经授权,严禁复制、发行、汇编、翻译或网络传播等,侵权必究。',
                    '本网站所提供的标准均为 PDF 格式电子版文本(可阅读打印),因数字商品的特殊性,一经售出,不提供退换货服务。',
                ],
                orderData: {},
                rechargeList: [10, 20, 50 , 100 ,200],
                payTypeList: [
                    {
                        type: 'balance',
                        text: '余额支付',
                        active: 'warning',
                        icon: 'icon-yue',
                        iconSize: '16px'
                    },
                  {
                    type: 'wechat',
                    text: '微信支付',
                    active: 'success',
                    icon: 'icon-zhifu-weixin',
                    iconSize: '24px'
                  },
                  {
                      type: 'alipay',
                      text: '支付宝',
                      active: '',
                      icon: 'icon-zhifubao',
                      iconSize: '20px'
                  }
                ],
                intervalId: '',
                isPayed: false,

            };
        },
        computed: {
            downFiles() {
                return Object.keys(this.archiveData).length > 0 ? this.archiveData[this.options.downType] : []
            },
            checks() {
                return Object.keys(this.archiveData).length > 0 ?this.downFiles.filter(item => item.check) : []
            }
        },
        methods: {

           changeType() {
               this.checkPayStatus('stop')
               this.displayType = 'pay'
           },
           beforeClose(done) {
               this.checkPayStatus('stop')
               // if (this.displayType === 'qrcode') {
               //     this.changeType()
               // } else {
               //     done()
               // }
               done()
           },
           checkPayStatus(type = 'start') {
               if (this.intervalId) {
                   window.clearInterval(this.intervalId)
               }
               if (type == 'stop') return
                 this.intervalId = window.setInterval(() => {
                    PayService.check({
                       orderid: this.orderData.orderid,
                       paytype: this.orderData.type,
                   }).then(res => {
                       // // console.log('checkPayStatus res', res)
                        const status = res.data
                        if (status == 'SUCCESS' || status == 'TRADE_SUCCESS') { // 支付成功
                            // $(".scanpay-qrcode .paid").removeClass("hidden");
                            // $(".scanpay-tips p").html("支付成功！<br><span>3</span>秒后将自动跳转...");
                            // this.$message.success('支付成功')
                            const loading = this.$loading({
                                lock: true,
                                text: '请稍等',
                                spinner: 'el-icon-loading',
                            });
                            setTimeout(() => {
                                if (this.options.type == 'down') {
                                    this.$message.success('支付成功，如未自动下载请重新点击下载！')
                                    this.down()
                                    Store.dispatch('getUserInfo')
                                    Store.dispatch('getOrderList')
                                }
                                // else if(this.options.type == 'read'){
                                //     this.$message.success('支付成功')
                                //     window.location.reload()
                                // }
                                else {
                                    this.$message.success('支付成功')
                                    window.location.reload()
                                }
                                loading.close();
                            }, 2000);
                            window.clearInterval(this.intervalId)
                        } else if (status == 'REFUND' || status == 'TRADE_CLOSED') { // 请求失败
                            // $(".scanpay-tips p").html("请求失败！<br>请返回重新发起支付");
                            this.$message.error('支付失败，请重新发起支付')
                            window.clearInterval(this.intervalId)
                            this.displayType = 'pay'
                        } else if (status == 'NOTPAY' || status == 'TRADE_NOT_EXIST') { // 未支付
                        } else if (status == 'CLOSED' || status == 'TRADE_CLOSED') { // 订单关闭
                            // $(".scanpay-tips p").html("订单已关闭！<br>请返回重新发起支付");
                            this.$message.error('订单已关闭，请重新发起支付')
                            window.clearInterval(this.intervalId)
                            this.displayType = 'pay'
                        } else if (status == 'USERPAYING' || status == 'WAIT_BUYER_PAY') { // 支付中
                        } else if (status == 'PAYERROR') { // 支付失败(其他原因，如银行返回失败)
                            this.$message.error('支付失败，请联系客服或重新发起支付')
                            // window.clearInterval(this.intervalId)
                            window.clearInterval(this.intervalId)
                            this.displayType = 'pay'
                        }
                   })
               }, 3500)
           },
           createQrcode() {
               this.displayType = 'qrcode'
               setTimeout(() => {
                   const qrcode = new QRCode(this.$refs.qrCodeUrl, {
                       text: this.orderData.codeurl, // 需要转换为二维码的内容
                       width: 200,
                       height: 200,
                       colorDark: '#000000',
                       colorLight: '#ffffff',
                       correctLevel: QRCode.CorrectLevel.H
                   })
                   this.orderData.qrcode = qrcode
                   this.checkPayStatus()
               },100)
           },
           balancePay() {
               // debugger
               //
               // this.dialogPwdVisible = true
           },
           async inputBlur() {
               const res = await UserService.checkpwd({password: this.password})
               this.pwdErr = !res.code && !res.data
           },
           async goPay() {
               if (this.paytype === 'balance' && Number(this.archiveData.price) > Number(this.userData.money)) {
                   this.$message.error('余额不足')
                   return
               }
               if (!this.checks.length && this.options.type == 'down') {
                   this.$message.error('请选择需要下载的文件')
                   return
               }
               if (this.paytype == 'balance') {
                   const res = await UserService.checkpwd({password: this.password})
                   this.pwdErr = !res.code && !res.data
                   if (this.pwdErr) {
                       return
                   }
               }
                let data = {
                   paytype: this.paytype,
                   method: 'scan'
                   // method: 'web'
                };
                let api = PayService.submit
                if (this.options.type === 'recharge') {
                    api = PayService.recharge
                    data.money = this.rechargeInputMoney || this.rechargeMoney
                } else {
                    data.id = this.archiveData.id
                }
                //非微信环境的wap 支付方法，会返回orderid，再模拟表单提交
                data.returnurl = window.location.href;
                let res = await api(data);
                if(!res.code){
                    this.$message.error(res.msg);
                    return;
                }
                this.orderData = res.data
                // // console.log('this.orderData', this.orderData)
                if(this.paytype=='balance'){
                    if (this.options.type == 'down') {
                        this.$message.success('支付成功，如未自动下载请重新点击下载！')
                        Store.dispatch('getUserInfo')
                        Store.dispatch('getOrderList')
                        this.down()


                    } else {
                        this.$message.success('支付成功')
                        window.location.reload()
                    }
                    return;
                }
                this.createQrcode()
                // this.create      //URL地址
               // if(res.data.toString().match(/^((?:[a-z]+:)?\/\/)(.*)/i)){
               //     location.href = res.data;
               //     return;
               // }
               //
               // //Form表单
               // document.getElementsByTagName("body")[0].innerHTML = res.data;
               // let form = document.querySelector("form");
               // if(form && form.length>0){
               //     form.submit();
               //     return;
               // }
               //
               // //Meta跳转
               // let meta = document.querySelector('meta[http-equiv="refresh"]');
               // if(meta && meta.length>0){
               //     setTimeout(function(){
               //         location.href = meta.content.split(/;/)[1];
               //     }, 300);
               //     return;
               // }

            },

            changePayType(type) {
                this.paytype = type
            },
            changeRechargeMoney(money) {
               if (money === -1 && this.rechargeInputMoney) {
                   this.rechargeMoney = ''
               } else {
                   this.rechargeMoney = money
                   this.rechargeInputMoney = ''
               }
            },
            show(options = {}) {
               // // console.log('this.login', this.$isLogin)
                if (!this.isLogin) {
                    this.$login.show()
                    return
                }
                this.options = options
                if (!this.options.downType) {
                    this.options.downType = 'fileInfo'
                }
                this.userData = Store.getters.user
                this.isLogin = Store.getters.isLogin
                this.displayType = 'pay'
                if (options.type != 'recharge') {
                    this.getDetail()
                } else {
                    this.dialogFormVisible = true
                }

            },
            checkOrder() {
               archivesService.checkOrder({ id: this.options.id }).then(res => {
                   if (res.code == 1) {
                       this.isPayed = res.data.paid
                       this.dialogFormVisible = true
                   }
               })
            },
            down() {
               // debugger
               if (!this.downFiles.length) {
                 this.$message.error('暂无相关文档可下载')
                 return
               }
               if (!this.checks.length ) {
                   this.$message.error('请选择需要下载的文件')
                   return
               }
               for(let item of this.checks) {
                   // // console.log('item1', item)
                   archivesService.download({
                       id: this.options.id,
                       downType: this.options.downType,
                       fileId: item.id
                   }).then(async res => {
                       // // console.log('res3332', res)
                       if (res.code) {
                         const { title, url } = res.data
                         const elink = document.createElement('a');
                         elink.download = title;
                         elink.target = '_blank';
                         elink.style.display = 'none';
                         elink.href = url;
                         // elink.href = 'https://jya360.com/' + url;
                         document.body.appendChild(elink);
                         elink.click();
                         URL.revokeObjectURL(elink.href);
                         document.body.removeChild(elink);
                           // this.dialogFormVisible = false
                           // const blob = new Blob([res.data]);
                           // const filename = `${item.filename}.${item.imagetype}`
                           // if ('download' in document.createElement('a')) {
                           //     const elink = document.createElement('a');
                           //     elink.download = filename;
                           //     elink.style.display = 'none';
                           //     elink.href = URL.createObjectURL(blob);
                           //     document.body.appendChild(elink);
                           //     elink.click();
                           //     URL.revokeObjectURL(elink.href);
                           //     document.body.removeChild(elink);
                           // } else {
                           //     navigator.msSaveBlob(blob, filename);
                           // }
                           // if (this.options.callback) {
                           //     this.options.callback()
                           // }

                       } else {
                           if (res.isLogin) {
                               this.dialogFormVisible = true
                           } else {
                               this.$login.show()
                           }

                       }
                   })
               }
            },
            getDetail() {
                archivesService.detail({id: this.options.id}).then(res => {
                    res.data[this.options.downType].map(item => {
                        if (this.options.fileId) {
                            item.check = this.options.fileId == item.id
                        } else {
                            item.check = true
                        }

                    })
                    this.archiveData = res.data
                    if (this.options.type == 'down') {
                        if (this.downFiles.length > 1) { // 大于1先判断是否购买，然后弹出多选下载框
                            this.checkOrder()
                        } else {
                            this.isPayed = false
                            this.down()
                        }
                    } else {
                        this.dialogFormVisible = true
                    }
                })
            }
        },
        mounted() {
            this.userData = Store.getters.user
            this.isLogin = Store.getters.isLogin
        }
    }
</script>

<style  lang="scss">
    .plug-pay{
        .title-block{
            background: $border_3;
            min-height: 60px;
            border-radius: 5px;
            padding: 10px;
            margin-top: 5px;
            .title{
                color: $font_1;
                /*overflow: hidden;*/
                /*text-overflow: ellipsis;*/
                /*word-break: keep-all;*/
                /*white-space: nowrap;*/

            }
            .size{
                font-size: 12px;
                color: $font_2;
                margin-top: 3px;
            }
        }
        .pay-type{
        /*    <!--background: $border_3;-->*/
            /*min-height: 100px;*/
            /*margin-top: 10px;*/
            border-radius: 5px;
            display: flex;
            padding: 15px 0;
            .el-tag{
                margin-right: 10px;
                height: 60px !important;
                /*padding: 10px 28px !important;*/
                /*line-height: 60px;*/
                cursor: pointer;
                width: 125px;
                display: grid;
                justify-content: center;
                align-items: center;
                text-align: center;
                /*padding: 10px;*/
                i{
                    vertical-align: bottom;
                    margin-right: 5px;
                }
            }
            .el-tag--info{
                /*background: white;*/
            }
        }
        .el-dialog{
            width: 430px !important;
            /*height: 500px;*/
            /*padding: 15px;*/
            border-radius: 5px;
            /*padding: 10px;*/
            .el-dialog__header{
                padding: unset !important;
            }
        }
        .el-dialog__body{
            padding: 40px 10px 20px 10px !important;
        }
        .el-dialog__headerbtn{
            top: 10px;
            right: 10px;
            font-size: 22px;
        }
        .el-tabs{
            position: absolute;
            top: 14px;
        }
        .recharge{
            .el-tag{
                font-size: 16px;
                width: 100%;
                margin: 5px 0;
                /* height: 40px; */
                height: 40px;
                line-height: 40px;
                text-align: center;
                cursor: pointer;
                span{
                    font-weight: 500;
                    margin-left: 3px;
                }

            }
            .el-input{
                margin: 5px 0;
            }
        }
        .qrcode-pay{
            .card{
                margin: 0 auto;
                width: 226px;
                min-height: 280px;
                border-radius: 10px;
                text-align: center;
            }
            .m-10-0{
                margin: 10px 0;
            }
            .pay-header{
                padding: 15px 0;
                width: 100%;
                color: white;
            }
            .qrcode{
                margin: 15px;
            }
            $wechar-color: rgb(34,172,56);
            .wechat{
                border: 2px solid $wechar-color;
                .pay-header{
                    background: $wechar-color;
                }
            }
            .alipay{
                border: 2px solid $brand_color;
                .pay-header{
                    background: $brand_color;
                }
            }
        }
    }

</style>