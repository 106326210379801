<template>
    <div class="top-menu">
        <div class="Tab-Label">
            <el-menu :default-active="defaultActive" class="el-menu-demo" mode="horizontal" @select="handleSelect">
                <el-menu-item index="1">首页</el-menu-item>
                <el-menu-item index="2" >个人中心</el-menu-item>
                <el-menu-item index="3">发布需求</el-menu-item>
                <el-menu-item index="4">实验室入驻</el-menu-item>
                <el-menu-item index="5" :disabled="true">
                    <el-popover
                        placement="bottom"
                        popper-class="record_tabs_cUDxaLxFxYh6GY11"
                        width="350"
                        trigger="hover">
                        <div slot="reference">联系我们</div>
                      <el-row>
                        <el-col :span="12">
                          <div>
                            <div class="contact_title">微信客服:</div>
                            <img src="../assets/images/kefu.jpg" alt="" style="width: 140px; height: 140px;">
                          </div>
                        </el-col>
                        <el-col :span="12">
                          <div>
                            <div class="contact_title">微信公众号:</div>
                            <img src="../assets/images/gzh.jpg" alt="" style="width: 146px; height: 146px;margin-top: -4px">
                          </div>
                        </el-col>
                      </el-row>
                      <el-row>
                        <div class="contact_title">
                          电话：0571-26255996
                        </div>
                        </el-row>


                    </el-popover>
                </el-menu-item>
            </el-menu>
        </div>
        <div class="Tab-b">
            <div class="record ">
<!--                <i class="iconfont icon-jilu"></i>-->
                <el-popover
                        placement="bottom"
                        width="250"
                        popper-class="record_tabs_cUDxaLxFxYh6GY11"
                        trigger="hover">
                    <div slot="reference">
                        <el-button type="text" >足迹</el-button>
                    </div>
                    <el-tabs v-model="activeName">
                        <el-tab-pane label="浏览记录" name="record"></el-tab-pane>
                        <el-tab-pane label="收藏记录" name="collect"></el-tab-pane>
                        <el-tab-pane label="购买记录" name="order"></el-tab-pane>
                        <div class="no-login" v-if="!isLogin" style="text-align: center;padding: 30px">
                            <el-button @click="login()" type="danger">请先登录</el-button>
                        </div>
                        <div class="list" v-else>
                            <div v-show="Object.keys(recordList).length > 0" :title="item.title" class="item" v-for="(item, index) in recordList" :key="index" @click="goDetail(item)">
                                <div class="title">{{item.title}}</div>
                                <div class="time">{{$formatDate(item.updatetime)}}</div>
                            </div>
                            <el-empty description="暂无数据" :image-size="100" v-show=" Object.keys(recordList).length === 0"></el-empty>
                        </div>
                    </el-tabs>
                </el-popover>

            </div>
            <div class="user">
                    <span v-if="!isLogin">
                         <el-button @click="login()" size="mini" type="danger">登录</el-button>
                    </span>
                <el-popover
                        placement="bottom"
                        width="300"
                        v-if="isLogin"
                        popper-class="user_popover_cUDxaLxFxYh6GY11"
                        trigger="hover">
                    <!--      trigger="click">-->
                    <div slot="reference">
                        <span @click="gouser">
                            <el-image :src="$imgurl + user.avatar">
                                    <div slot="error" class="image-slot">
                                                  <img src="../assets/images/userimg.png" width="25"/>
<!--                                        <i class="iconfont icon-biaoqiankuozhan_wode-135"></i>-->
                                    </div>
                            </el-image>
                           <el-button  type="text">{{user.nickname || user.username ||  user.mobile}}</el-button>
                        </span>
                    </div>
                    <div class="card">
                        <el-row>
                            <el-col :span="4">
                                <el-image :src="$imgurl + user.avatar" @click.native="gouser">
                                    <div slot="error" class="image-slot">
                                        <img src="../assets/images/userimg.png" width="40"/>
<!--                                        <i class="iconfont icon-biaoqiankuozhan_wode-135"></i>-->
                                    </div>
                                </el-image>
                            </el-col>
                            <el-col :span="17">
                                <p>{{user.nickname || user.username ||  user.mobile}}</p>
                                <p>
                                    <span>余额：{{user.money || 0.00}}</span>
                                    <el-button @click="goRecharge" size="mini" type="danger">充值</el-button>
                                </p>
                            </el-col>
                            <el-col :span="3" style="text-align: center">
                                <el-popconfirm
                                        title="确定退出登录吗？"
                                        @confirm="loginout"
                                >
                                    <el-button type="text" slot="reference" >退出</el-button>
                                </el-popconfirm>

                            </el-col>
                        </el-row>
                        <el-row class="operate">
                            <el-col :span="6" v-for="(item, index) in operateList" @click.native="toJump(item.path)" :key="index">
                                <p>
                                    <i class="iconfont" :class="item.icon"></i>
                                </p>
                                <p>
                                    {{item.text}}
                                </p>
                            </el-col>
                        </el-row>
                    </div>


                </el-popover>


            </div>

      <!--      <el-button type="primary">登录</el-button>
            <el-button>注册</el-button>-->
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'
    import {UserService} from "../api/user";
    import Store from "../store";
    export default {
        name: "TopMenu",
        props: {
            defaultActive: {
                type: String,
                default: '',

            }
        },
        computed: {
            ...mapState(['commonRecord']),
            ...mapGetters(['user', 'isLogin']),
            recordList() {
               return this.commonRecord[this.activeName] || []
            }
        },
        data() {
            return {
                operateList: [
                    {
                        text: '我的下载',
                        icon: 'icon-drxx07',
                        path: '/user/document'
                    },
                    {
                        text: '我的收藏',
                        icon: 'icon-shoucang2',
                        path: '/user/collect'
                    },
                    {
                        text: '消息通知',
                        icon: 'icon-xiaoxi2',
                        path: '/user/message'
                    },
                    {
                        text: '账号设置',
                        icon: 'icon-shezhi1',
                        path: '/user/setting'
                    }
                ],
                activeName: 'record',
                // activeIndex: '1',
                search: '',
                tabs: [
                    {
                        label: '浏览记录',
                        type: 'record'
                    },
                    {
                        label: '浏览记录',
                        type: 'collect'
                    },
                    {
                        label: '购买记录',
                        type: 'buy'
                    }
                ]
            }
        },
        methods: {
            loginout() {
                UserService.logout().then(res => {
                  this.$message.success('退出登录成功!')
                  Store.commit('loginout')
                  /*  if (this.$route.path.includes('/user/')) {
                        this.$router.replace('/')
                    } else {
                        window.location.reload()
                    }*/
                  setTimeout(() => {
                    window.location.reload()
                  }, 100)

                    // if (res.code) {
                    //
                    // }
                })
            },
            gouser() {
                // // console.log('this.router', this.$route)
                if (this.$route.path.includes('/user')) return
                this.toJump('/user/center')
            },
            goRecharge() {
                this.$pay.show({
                    type: 'recharge'
                })
            },
            goDetail(item) {
                // // console.log('item', item)
                const id = item.archives_id || item.aid
                let name = 'detail'
                if (item.aid && item.type === 'channel') {
                    name = 'detailLaboratory'
                }
                if (this.$route.query.id === id) return
                if (this.$route.name !== name) {
                    this.$router.push({
                        query: {
                            id
                        },
                        name
                    })
                } else {
                    let query = this.$router.history.current.query;
                    let path = this.$router.history.current.path;
                    //对象的拷贝
                    let newQuery = JSON.parse(JSON.stringify(query));
                    // 地址栏的参数值赋值
                    newQuery.id = id;
                    this.$router.replace({ path, query: newQuery });
                }

            },
            login() {
                this.$login.show()
            },
            // 跳转至其他页面
            toJump(type) {
                this.$router.push(type)
            },
            handleSelect(key, keyPath) {
                if (key === this.defaultActive) return
                switch (Number(key)) {
                    case 1:
                        this.toJump('/')
                        break;
                    case 2:
                        this.toJump('/user/center')
                        break;
                    case 3:
                        this.toJump('/demand')
                        break;
                    case 4:
                        this.toJump('/laboratory')
                        break;
                    case 5:
                        break;
                    case 6:
                        break;
                    default:
                        break;
                }
                // // console.log(key, keyPath);
            }
        },
        mounted() {
          // console.log('isLogin', this.isLogin)
        }
    }
</script>

<style scoped lang="scss">
    .top-menu {
        //width: 1230px;
        height: 100%;
        min-height: 100%;
        margin: 0 auto;
        .el-menu{
            background: transparent;
        }
        .el-menu-item{
            color: white ;
        }
        .el-menu--horizontal>.el-menu-item.is-active{
            color: black;
            background-color: white;
        }
        .el-menu.el-menu--horizontal{
            border: none !important;
        }
        .el-menu-item.is-disabled{
             opacity: unset !important;
             cursor: pointer;
             background: transparent !important;
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
        /*margin-left: 15px;*/
        background: transparent;
        /*padding: 0 15px;*/

        .Tab-Label {
            .connectUs:hover {
                /*position: absolute;*/
                width: 80px;
                height: 80px;
                background: url('../assets/images/code.png') center center no-repeat;
                background-size: contain;
            }
        }
        .Tab-b{
            display: flex;
            /*margin-right: 20px;*/
            font-size: 16px;
            justify-content: center;
            align-items: center;
            i{
                vertical-align: text-bottom;
            }

            .record{
                .el-button{
                    color: white;
           /*         color: $font_1;*/
                }
            }
            button{
                margin: 0 2px;
                color: white;
            }
            .user{
                margin: 0 15px;
                cursor: pointer;
                .el-image{
                    width: 25px;
                    height: 25px;
                    align-items: center;
                    vertical-align: middle;
                    text-align: center;
                    line-height: 26px;
                    border-radius: 50px;
                    border: 1px solid $brand_color;
                    i{
                        font-size: 20px;
                    }
                }
                .image-slot{

                }
            }
        }

    }

</style>
<style lang="scss">

    .record_tabs_cUDxaLxFxYh6GY11{
      .contact_title{
        margin: 5px;
      }
       .list{
            /*height: 180px;*/
           overflow: auto;
           height: 150px;
           .el-empty{
               padding: 0 !important;
           }
           .item{
               display: flex;
               line-height: 1.8;
               cursor: pointer;
               .title{
                   flex: 0.6;
                   color: $font_2;
                   display: block;
                   overflow: hidden;
                   word-break: keep-all;
                   white-space: nowrap;
                   text-overflow: ellipsis;
               }
               .time{
                   color: $font_3;
                   flex: 0.4;
                   text-align: right;
                   margin: 3px 0;
               }
           }
        }
    }
    .user_popover_cUDxaLxFxYh6GY11{
        /*background: red;*/
        .card{
            height: 125px;
            padding: 10px;
            .el-image{
                width: 40px;
                height: 40px;
                align-items: center;
                vertical-align: middle;
                text-align: center;
                line-height: 40px;
                border-radius: 50px;
                border: 1px solid $brand_color;
                i{
                    font-size: 35px;
                }
            }
            p{
                margin: 0 0 3px 3px;
                .el-button{
                    padding: 2px 3px;
                    margin-left: 5px;
                    vertical-align: text-top;
                }
            }

            .operate{
                width: 115%;
                margin-top: 15px;
                .el-col{
                    /*text-align: center;*/
                    /*margin: 0 10px;*/
                    cursor: pointer;
                    p:nth-child(1){
                        background: aliceblue;
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                         text-align: center;
                        /*margin: 5px auto;*/
                        margin: 5px 0;
                        border-radius: 50px;
                    }
                    p:nth-child(2){
                        margin-left: -4px;
                        padding-top: 5px;
                        font-size: 13px;
                    }
                    i{
                        color: #409EFF;
                        font-size: 24px;
                    }
                }
            }
        }
    }
</style>