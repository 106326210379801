import http from "../utils/http";

export const archivesService = {
    list(data, hideLoad = false) {
        return http.post('archives/list', data, {
            hideLoad
        })
    },
    detail(data) {
        return http.post('archives/detail', data)
        // return http.post('archives/detail?XDEBUG_SESSION_START=14470', data)
    },
    download(data) {
        // return http.post('archives/download', data, {
        //     responseType: 'arraybuffer',
        //     showLogin: true
        // })
        return http.post('archives/download', data)
    },
    read(data) {
        return http.post('archives/read', data)
    },
    collection(data) {
        return http.post('collection/create', data, {
                showLogin: true
            }
        )
    },
    removeCollect(data) {
        return http.post('collection/remove', data, {
            showLogin: true
        })
    },
    collectCount(data) {
        return http.post('collection/count', data, {
            // showLogin: true,
            hideLoad: true
        })
    },
    checkOrder(data) {
        return http.post('archives/checkOrder', data, {
            showLogin: true
        })
    },
    laboratory(data) {
        return http.post('archives/laboratory', data, {
            showLogin: true
        })
    },
    recommend(data) {
        return http.post('archives/recommend', data)
    }
}
