import http from "../utils/http";
//
export const UserService = {
    // 会员信息
    index(data) {
        return http.post('/user/index', data);
    },
    // 会员中心
    center(data) {
        return http.post('/user/center', data);
    },
    // 订单列表
    order(data) {
        return http.post('/user/order', data, { hideLoad: true });
    },
    // 收藏列表
    collection(data) {
        return http.post('/user/collection', data, { hideLoad: true });
    },
    // 浏览记录
    record(data) {
        return http.post('/user/record', data, { hideLoad: true });
    },
    // 更改信息
    profile(data) {
        return http.post('/user/profile', data);
    },
    // 发票列表
    moneylog(data) {
        return http.post('/user/moneylog', data);
    },
    // 发票列表
    invoice(data) {
        return http.post('/user/invoice', data);
    },
    // 发票列表
    invoiceSum(data) {
        return http.post('/user/invoiceSum', data);
    },
    // 发票列表
    setInvoice(data) {
        return http.post('/user/setInvoice', data);
    },
    // 信息列表
    message(data) {
        return http.post('/user/message', data);
    },
    // 需求列表
    demand(data) {
        return http.post('/user/demand', data);
    },
    // 信息列表
    messagedetail(data) {
        return http.post('/user/messagedetail', data);
    },
    // 信息列表
    messagedelete(data) {
        return http.post('/user/messagedelete', data);
    },
    // 已下载文档
    downloads(data) {
        return http.post('/user/downloads', data);
    },
    // 登录
    login(data) {
        return http.post('/user/login', data);
    },    // 退出登录
    logout(data) {
        return http.post('/user/logout', data);
    },
    // 重置密码
    resetpwd(data) {
        return http.post('/user/resetpwd', data);
    },
    // 重置密码
    userResetpwd(data) {
        return http.post('/user/userResetpwd', data);
    },
    // 验证码登录
    mobilelogin(data) {
        return http.post('/user/mobilelogin', data);
    },
    // 注册
    register(data) {
        return http.post('/user/register', data);
    },
    // 注册
    changemobile(data) {
        return http.post('/user/changemobile', data);
    },
    // 注册
    adddemand(data) {
        return http.post('/user/adddemand', data);
    },
    // 注册
    addsettled(data) {
        return http.post('/user/addsettled', data);
    },
    // 注册
    checkuser(data) {
        return http.post('/user/checkuser', data, { hideLoad: true });
    },
    // 注册
    checkpwd(data) {
        return http.post('/user/checkpwd', data, { hideLoad: true });
    },
    // 注册
    wxlogin(data) {
        return http.post('/user/wxlogin', data, { hideLoad: true });
    },
    // 注册
    codekey(data) {
        return http.post('/user/codekey', data, { hideLoad: true });
    },
    // 注册
    codeticket(data) {
        return http.post('/user/codeticket', data, { hideLoad: true });
    }
}