
// 设置用户信息
export function setUserData(data) {
    if (data) {
        localStorage.setItem('USER_DATA', JSON.stringify(data))
        // sessionStorage.setItem('USER_DATA', JSON.stringify(data))
    }
}
// 获取用户信息
export function getUserData() {
    const data = localStorage.getItem('USER_DATA')
    // const data = sessionStorage.getItem('USER_DATA')
    if (data) {
        return JSON.parse(data)
    }
    return {}
}

// 设置token
export function setToken(data) {
    if (data) {
        localStorage.setItem('USER_TOKEN', data)
        // sessionStorage.setItem('USER_TOKEN', data)
    }
}
// 获取token
export function getToken() {
    const data = localStorage.getItem('USER_TOKEN')
    // const data = sessionStorage.getItem('USER_TOKEN')
    if (data) {
        return data
    }
    return ''
}

// 设置跳转登录前页面
export function setLastPage(data) {
    if (data) {
        sessionStorage.setItem('LAST_PAGE', data)
    }
}
// 获取跳转登录前页面
export function getLastPage() {
    const data = sessionStorage.getItem('LAST_PAGE')
    if (data) {
        return data
    }
    return '/'
}
// 设置验证码倒计时
export function setTimeDown(data) {
    if (data) {
        sessionStorage.setItem('TIME_DOWN', data)
    }
    return '/'
}
// 获取验证码倒计时
export function getTimeDown() {
    const data = sessionStorage.getItem('TIME_DOWN')
    if (data) {
        return data
    }
    return 0
}

export function setSearchParams(data) {
    if (data) {
        sessionStorage.setItem('SEARCH_PARAMS', JSON.stringify(data))
    }
}

export function getSearchParams() {
    const data = sessionStorage.getItem('SEARCH_PARAMS')
    if (data) {
        return JSON.parse(data)
    }
    return {}
}
export function setSearchChannelParams(data) {
    if (data) {
        sessionStorage.setItem('SEARCH_CHANNEL_PARAMS', JSON.stringify(data))
    }
}

export function getSearchChannelParams() {
    const data = sessionStorage.getItem('SEARCH_CHANNEL_PARAMS')
    if (data) {
        return JSON.parse(data)
    }
    return {}
}
export function setLoginData(data) {
    if (data) {
        localStorage.setItem('LOGIN_DATA', JSON.stringify(data))
    } else {
        localStorage.removeItem('LOGIN_DATA')
    }
}

export function getLoginData() {
    const data = localStorage.getItem('LOGIN_DATA')
    if (data) {
        return JSON.parse(data)
    }
    return ''
}
export function setPointData(data) {
    if (data) {
        localStorage.setItem('POINT_DATA', JSON.stringify(data))
    } else {
        localStorage.removeItem('POINT_DATA')
    }
}
export function getPointAllData() {
    const data = localStorage.getItem('POINT_DATA')
    if (data) {
        return JSON.parse(data)
    }
    return ''
}
export function getPointData() {
    const data = localStorage.getItem('POINT_DATA')
    if (data) {
        const jData = JSON.parse(data)
        if (jData.lat) {
            return jData
        } else {
            return jData.position
        }
    }
    return ''
}

export function setKeyWord(data) {
    if (data) {
        localStorage.setItem('KEY_WORD', data)
    } else {
        localStorage.removeItem('KEY_WORD')
    }
}
export function getKeyWord() {
    return  localStorage.getItem('KEY_WORD')
}
export function setKeyWordType(data) {
    if (data) {
        localStorage.setItem('KEY_WORD_TYPE', data)
    } else {
        localStorage.removeItem('KEY_WORD_TYPE')
    }
}
export function getKeyWordType() {
    return  localStorage.getItem('KEY_WORD_TYPE')
}