import http from "../utils/http";
import axios from 'axios'
export const sendcode = (data) => {
    return http.post('/common/sendcode', data);
}
export const upload = (data) => {
    // debugger
    return http.post('/common/upload', data, {
        showLogin: true,

        headers:{'Content-Type':'multipart/form-data'}
    });
}
export const captcha = () => {
    return http.post(`/common/captcha`,{}, {
        hideLoad: true
    })
}
export const setLoginCount = () => {
    return http.post(`/common/setLoginCount`,{}, {
        hideLoad: true
    })
}
export const getLatLng = (address, city) => {
    const key = "f0738401320b2b419131b26eb89d0e23"
    return axios.get(`https://restapi.amap.com/v3/geocode/geo?parameters&key=${key}&address=${address}&city=${city}`)
}
export const getCityList = () => {
    return new Promise((resolve, reject) => {
        http.post(`/common/city`,{}, {
            hideLoad: true
        }).then(res => {
           // // console.log('222', res)
            const arr = [
                {
                    label: '全部',
                    value: ''
                }
            ]
            const hasKey = {}
            res.data.forEach(item => {
                item.label = item.name
                item.value = item.id
                if (hasKey[item.pid]) {
                    arr.forEach(data3 => {
                        if (data3.value === item.pid) {
                           data3.children.push(item)
                        }
                    })
                } else {
                    hasKey[item.pid] = true
                    const data2 = item.mergename.split(',')

                    arr.push({
                        label: data2[1],
                        value: item.pid ,
                        children: [item]
                    })
                }

            })
            resolve(arr)
            // // console.log('222', arr)
        })
    })
}
