import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import account from "./account";
import detail from "./detail";
import laboratory from "./laboratory";
import list from "./list";
import user from "./user";
import Store from '../store'
import Login from '../plugins/login'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      deepth: 0.5
    }
  },
    ...user,
    ...list,
    ...laboratory,
    ...detail,
    ...account
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.login && Object.keys(Store.getters.user).length === 0) {
    Login(Vue).show()
    return
  }
  next()
});

export default router
