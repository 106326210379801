export default [
    {
        path: '/laboratory',
        name: 'laboratory',
        meta: {},
        component: () => import('../views/laboratory')
    },
    {
        path: '/demand',
        name: 'demand',
        meta: {},
        component: () => import('../views/laboratory/demand')
    },
    {
        path: '/laboratory/submit',
        name: 'laboratorySubmit',
        meta: {},
        component: () => import('../views/laboratory/submit')
    }
]