import http from "../utils/http";

export const PayService = {
    submit(data) {
        return http.post('/pay/submit', data, {
            showLogin: true
        });
    },
    check(data) {
        return http.post('/pay/check', data, {
            hideLoad: true,
            showLogin: true

        });
    },
    recharge(data) {
        return http.post('/pay/recharge', data, {
            showLogin: true
        });
    }
}