export default [
    {
        path: '/list',
        name: 'list',
        meta: {
            keepAlive: true,
            deepth: 1
        },
        redirect: '/list/ability',
        component: () => import('../views/list'),
        children: [
            {
                path: 'ability',
                name: 'ListAbility',
                meta: {},
                component: () => import('../views/list/child/list_1'),   // 实验室能力列表
            },
            {
                path: 'laboratory',
                name: 'ListLaboratory',
                meta: {},
                component: () => import('../views/list/child/list_2'),   // 实验室能力列表
            }
        ]
    }
]
