export const TAG_TYPES = {
  CNAS: {
    type: '',
    text: 'CNAS报告'
  },
  CMA: {
    text: 'CMA报告',
    type: 'success'
  },
  MAP: {
    text: '摸底报告',
    type: 'info'
  },
  MAPD: {
    text: '摸底数据',
    type: 'warning'
  },
  IDENT: {
    text: '认证',
    type: 'danger'
  },
  COMPARE: {
    text: '机构比对',
    type: ''
  }
}
