export default [
    {
        path: '/user',
        name: 'user',
        meta: {
            login: true
        },
        // redirect: '/user/balance',
        component: () => import('../views/user'),
        children: [
            {
                path: 'balance',
                name: 'UserBalance',
                meta: {},
                component: () => import('../views/user/balance'),   // 会员中心
            },
            {
                path: 'center',
                name: 'UserCenter',
                // meta: {
                //     login: true
                // },
                component: () => import('../views/user/center'),   // 我的余额
            },
            {
                path: 'collect',
                name: 'userCollect',
                meta: {},
                component: () => import('../views/user/collect'),   // 我的收藏
            },
            {
                path: 'demand',
                name: 'userDemand',
                meta: {},
                component: () => import('../views/user/demand'),   // 我的需求
            },
            {
                path: 'document',
                name: 'userDocument',
                meta: {},
                component: () => import('../views/user/document'),  // 我的文档
            },
            {
                path: 'invoice',
                name: 'userInvoice',
                meta: {},
                component: () => import('../views/user/invoice'),   // 发票管理
            },
            {
                path: 'message',
                name: 'userMessage',
                meta: {},
                component: () => import('../views/user/message'),   // 消息管理
            },
            {
                path: 'order',
                name: 'userOrder',
                meta: {},
                component: () => import('../views/user/order'),   // 我的订单
            },
            {
                path: 'record',
                name: 'userRecord',
                meta: {},
                component: () => import('../views/user/record'),   // 浏览记录
            },
            {
                path: 'setting',
                name: 'userSetting',
                meta: {},
                component: () => import('../views/user/setting'),   // 账号设置
            }
        ]
    }
]
