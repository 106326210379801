import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import plugins from './plugins'
import ElementUi from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import dayjs from 'dayjs'

import { setLoginCount } from '@/api/common'
Vue.config.productionTip = false
Vue.use(ElementUi);
Vue.use(plugins)
router.beforeEach((to, from, next) => {
  const saveDate = localStorage.getItem('SET_LOGIN_DATA') ? JSON.parse(localStorage.getItem('SET_LOGIN_DATA')): {}
  const today = dayjs().format('YYYY-MM-DD')
  // console.log(222, saveDate, today)
  if ((saveDate.date !== today) || (!saveDate.isLogin && store.getters.isLogin)) {
    setLoginCount().then(res => {
      if (res.data) {
        const data = {
          isLogin: res.isLogin,
          date: today
        }
        localStorage.setItem('SET_LOGIN_DATA', JSON.stringify(data))
      }
    }
  )}
  next()
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
