<template>
  <div class="home">
    <div class="center">
      <div class="center_title">
        <img src="../assets/images/jya-icon.svg" style="width: 200px"/>
      </div>
      <el-input placeholder="请输入检索内容" @keyup.enter.native="toList" v-model="search" class="input-with-select">
        <el-select slot="prepend" v-model="value">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <el-button slot="append" icon="el-icon-search" @click="toList"></el-button>
      </el-input>
      <div class="bottom">
        <div class="bottom_title">热门推荐</div>
        <el-row>
          <el-col :span="6" v-for="(item, index) in recommendKW" @click.native="clickItem(item)">
            <el-link  :underline="false" :class="index < 4 ? 'new' : index < 6 ? 'hot' : ''">{{item}}</el-link>
          </el-col>
        </el-row>
<!--        <div class="bottom_list" v-for="(item, index) in list" @click="goDetail(item)">
          <span><span class="list-index" :class="`hot_${index}`">{{index + 1}}</span>{{item.title}}</span>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { archivesService } from '../api/archives'
import {setKeyWord, setKeyWordType} from "../utils/storageUtils";
export default {
  name: 'Home',
  data() {
    return {
      value: 'detection_standard',
      options: [
        // {
        //   value: '1',
        //   label: '检测能力'
        // },
        {
          value: 'detection_standard',
          label: '检测标准'
        },
          {
          value: 'detection_obj',
          label: '检测对象'
        }, {
          value: 'detection_items',
          label: '检测项目'
        },
        // {
        //   value: '2',
        //   label: '实验室'
        // },
        // {
        //   value: '',
        //   label: '默认筛选'
        // },

        {
        value: 'name',
        label: '实验室名'
      },
        {
          value: 'province',
          label: '所属省份'
        },
        {
          value: 'city',
          label: '所属城市'
        },
      ],
      activeIndex: '2',
      search: '',
      list: [],
      recommendKW: [
        '防护服',
         '口罩',
         '消毒',
         '医疗器械',
         '电动汽车',
         '电线电缆',
         '通信协议',
         '电子器械',
      ],
    }
  },
  methods: {
    clickItem(text) {
      this.search = text
      this.toList()
    },
    toList() {
      if (!this.search) {
        this.$message.warning('请输入关键字进行搜索');
        return
      }
      this.search = this.search.replaceAll(/\s/g,"").replaceAll('；', ';');
      setKeyWord(this.search)
      setKeyWordType(this.value)
      if (['name', 'city', 'province', '2'].includes(this.value)) {
        this.$router.push({
          path: '/list/laboratory',
          params: {
            search: this.search
          }
        })
      } else {
        this.$router.push({
          name: 'list',
          params: {
            search: this.search
          }
        })
      }

    },
    goDetail(item) {
      // // console.log('item', item)
      this.$router.push({
        query: {
          id: item.id
        },
        name: 'detail'
      })
    },
    handleSelect(key, keyPath) {
      // // console.log(key, keyPath);
    },
    getList() {
      archivesService.list().then(res => {
        // // console.log('res', res)
        this.list = res.data.list
      })
    }
  },
  mounted() {
    // this.getList()
  },
  beforeRouteLeave(to, form, next) {
    debugger
    if (to.name === 'list') {
      to.meta.keepAlive = true
    }
    next()
  }
}
</script>
<style lang="scss" scoped>
  .home {
    .center {
      width: 50%;
      margin: 0 auto;
      margin-top: 4%;
      .center_title {
        text-align: center;
        font-size: 40px;
        font-weight: bold;
        font-family: Microsoft YaHei;
        //margin-bottom: 30px;
        /*margin-bottom: 25px;*/
      }
    }
    .bottom {
      .el-col-6{
        margin: 10px 0;
        color: $font_2;
        .new{
          &::after{
            content: '\65b0';
            margin-left: 5px;
            display: block;
            border: 1px solid $danger;
            padding: 0 2px;
            color: $danger;
          }
        }
        .hot{
          &::after{
            content: '\70ed';
            margin-left: 5px;
            display: block;
            border: 1px solid $danger;
            padding: 0 2px;
            background: $danger;
            color: white;
          }
        }
      }
      text-align: left;
      margin-top: 25px;


    }
  }
</style>
<style lang="scss">
  .home {
    .el-input__inner{
      height: 50px;
      font-size: 15px;
    }
    .el-select-dropdown__item{
      font-size: 16px !important;
    }

    .el-select{
      width: 100px;
      border-top-left-radius: 15px;
      .el-input__inner{

        font-size: 17px;
        padding: 0 2px ;
      }
      .el-input__suffix{
        top: 3px;
      }
      .el-select__caret{
        font-size: 19px !important;
      }
    }
    .el-input-group--append .el-input-group__prepend {
      //height: 50px;
      //padding: 30px;
    }
    .el-menu.el-menu--horizontal {
      border-bottom: unset;
    }

    .el-input__inner {
      border: 2px solid #DCDFE6;
      font-size: 15px;
      //border-radius: 15px 0 0 15px;
    }
    .el-input-group__append {
      border: 2px solid #DCDFE6;
      border-left: 0;
      border-radius: 0 15px 15px 0;
    }
  }
</style>
