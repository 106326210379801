import Vue from 'vue'
import Vuex from 'vuex'
import {setUserData, getUserData, getLastPage} from '../utils/storageUtils'
import {UserService} from "../api/user";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userData: getUserData(),
    commonRecord: {
      record: [],
      order: [],
      collect: []
    },
    isLogin: true,
  },
  mutations: {
    setUserData(state, data) {
      // // console.log('data222', data)
      state.userData = data
      // Vue.prototype.user = data
      setUserData(data)
    },
    loginout(state) {
      state.userData = {}
      setUserData({})
      // localStorage.removeItem('SET_LOGIN_DATA')
    },
    setCommonRecord(state, data) {
      const { key, value } = data
      state.commonRecord[key] = value
    }
  },
  getters: {
    user(state) {
      if (Object.keys(state.userData).length > 0) {
        return state.userData
      }
      return getUserData()
    },
    isLogin(state) {
      return state.userData ? Object.keys(state.userData).length : false
    }
  },
  actions: {
    getUserInfo({ commit }) {
      return new Promise((resolve) => {
        UserService.index().then(res => {
          commit('setUserData', res.data.userInfo)
          resolve(res.data.userInfo)
        })
      })
    },
    getRecordList({ commit }) {
      return new Promise(() => {
        UserService.record({ pageSize: 20 }).then(res => {
          commit('setCommonRecord', {
            key: 'record',
            value: res.data.data
          })
        })
      })
    },
    getCollList({ commit }) {
      return new Promise(() => {
        UserService.collection({ pageSize: 20 }).then(res => {
          commit('setCommonRecord', {
            key: 'collect',
            value: res.data.data
          })
        })
      })
    },
    getOrderList({ commit }) {
      return new Promise(() => {
        UserService.order({ pageSize: 20 }).then(res => {
          commit('setCommonRecord', {
            key: 'order',
            value: res.data.data
          })
        })
      })
    }
  },
  modules: {
    setLoginState(state, data) {
      // console.log('222data', data)
      state.isLogin = data
    }
  }
})
