export default [
    {
        path: '/forget',
        name: 'forget',
        meta: {
            hideMenu: true
        },
        component: () => import('../views/account/forget')
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            hideMenu: true
        },
        component: () => import('../views/account/login')
    },
    {
        path: '/register',
        name: 'register',
        meta: {
            hideMenu: true
        },
        component: () => import('../views/account/register')
    },
]