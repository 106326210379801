import axios from 'axios'
import { Loading, Message  } from 'element-ui'
import Router from '../router'
import {setUserData, getUserData, getToken, setLastPage} from '../utils/storageUtils'
import Store from "../store";
import login from "../plugins/login";
const http = axios.create({
	baseURL: '/api/jya/',
	timeout: 20000, // request timeout
})
let loading;
// 显示加载动画
function startLoading (){
	loading = Loading.service({
		lock: true,
		text: '加载中...',
		background: 'rgba(0, 0, 0, 0.3)'
	});
}
// 隐藏加载动画
function endLoading() {    //使用Element loading-close 方法
	if (loading) {
		loading.close()
	}

}
// http request 拦截器
http.interceptors.request.use(
	(config) => {
		if (!config.hideLoad) {
			startLoading()
		}
		if (config.showLogin && !Store.getters.isLogin) {
			login().show()
			return Promise.reject({
				code: 0
			})
		}
		config.headers['token'] = getToken()
		return config
	},
	(error) => {
		endLoading()
		return Promise.reject(error)
	}
)

// http response 拦截器
http.interceptors.response.use(
	(res) => {
		// // console.log('response', res)
		endLoading()
		// debugger

		let message = ''
		if (res.config.responseType === "arraybuffer") {
			const enc = new TextDecoder('utf-8')
			const json = enc.decode(new Uint8Array(res.data))
			if (json.includes('"code":0')) {
				return JSON.parse(json)
			}
			return {
				code: 1,
				data: res.data
			}
		}

		if (!res.data.isLogin) {
			Store.commit('loginout')
			if (res.config.showLogin) {
				login().show()
				return Promise.reject(res)
			}
		}
		switch (Number(res.data.code)) {
			case 0:
				// message = res.data.msg
				break;
			case 1:
				break
			case 400:
				message = '请求错误(400)'
				break
			case 401:
				// message = '未授权，请重新登录(401)'
				break
			case 403:
				message = '拒绝访问(403)'
				break
			case 404:
				message = '请求出错(404)'
				break
			case 408:
				message = '请求超时(408)'
				break
			case 500:
				message = '服务器错误(500)'
				break
			case 501:
				message = '服务未实现(501)'
				break
			case 502:
				message = '网络错误(502)'
				break
			case 503:
				message = '服务不可用(503)'
				break
			case 504:
				message = '网络超时(504)'
				break
			case 505:
				message = 'HTTP版本不受支持(505)'
				break
			default:
				message = `连接出错(${res.data.code})!`
				break;
		}
		if (message) {
			Message.error({
				title: '提示',
				message
			});
			return Promise.reject(res)
		}
		return res.data
	},
	(error) => {
		endLoading()
		if (!error.response.data) return
		// // console.log('error', error.response)
		// // console.log('请求出错', error.response.data)
		error.data = {}
		if (error.response.data.code == 401) {
			Store.commit('loginout')
			return Promise.reject(error)
		}
		Message.error({
			title: '错误提示',
			message: '请求超时或服务器异常，请检查网络或联系管理员！'
		});
		error.data.msg = '请求超时或服务器异常，请检查网络或联系管理员！'
		return Promise.reject(error)
	}
)

export default http

