export default [
    {
        path: '/detail',
        name: 'detail',
        meta: {
            deepth: 2
        },
        component: () => import('../views/detail')
    },
    {
        path: '/detail/read',
        name: 'detailRead',
        meta: {
            login: true
        },
        component: () => import('../views/detail/read')
    },
    {
        path: '/detail/laboratory',
        name: 'detailLaboratory',
        meta: {
            login: true
        },
        component: () => import('../views/detail/laboratory')
    }
]