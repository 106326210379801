<template>
    <div id="app">
        <el-container>
            <el-header>
                <top-menu :default-active="defaultActive" v-show="!isHide"/>
            </el-header>
            <el-main>
                <!--        <keep-alive>-->
                <!--          &lt;!&ndash; 需要缓存的视图组件 &ndash;&gt;-->
                <!--          <router-view v-if="$route.meta.keepAlive"  >-->
                <!--          </router-view>-->
                <!--        </keep-alive>-->

                <!--        &lt;!&ndash; 不需要缓存的视图组件 &ndash;&gt;-->
                <!--        <router-view v-if="!$route.meta.keepAlive">-->
                <!--        </router-view>-->
                <router-view></router-view>

            </el-main>
            <el-footer>
                <span>杭州艾姆西技术服务有限公司&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2021032374号</a> |&nbsp;&nbsp;经营许可证编号 浙B2-20220246 | 浙公网安备 33011002016065号</span>
                <!--            <div style="width:300px;margin:0 auto; padding:20px 0;">-->
                <!--              <a target="_blank" href=" " style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">浙公网安备 33011002016065号</p ></a >-->
                <!--            </div>-->

            </el-footer>
        </el-container>

    </div>

</template>
<script>
    import TopMenu from "./components/TopMenu";
    import {getToken, setPointData} from "./utils/storageUtils";
    import router from './router'
    export default {
        data() {
            return {}
        },
        components: {TopMenu},
        computed: {

            isHide() {
                return this.$route.meta && this.$route.meta.hideMenu
            },
            isKeepAlive() {
                return this.$route.meta && this.$route.meta.keepAlive
            },
            defaultActive() {
                const routeIndex = {
                    'Home': '1',
                    'demand': '3',
                    'laboratory': '4',
                    'laboratorySubmit': '4',
                }
                if (this.$route.path.includes('/user/')) {
                    return '2'
                }
                return routeIndex[this.$route.name] || ''
            }
        },


      mounted() {

            this.getLocation()
            if (getToken()) {
                this.$store.dispatch('getUserInfo')
                setTimeout(() => {
                    this.$store.dispatch('getRecordList')
                    this.$store.dispatch('getCollList')
                    this.$store.dispatch('getOrderList')
                }, 3000)
            }

        },
        methods: {
            successPosition(res) {

            },
            getLocation() {
              // console.trace(22)
                     // var options = {
                     //     enableHighAccuracy: true,
                     //     timeout: 5000,
                     //     maximumAge: 0
                     // };
                     //
                     // function success(pos) {
                     //     var crd = pos.coords;
                     //
                     //     // console.log('Your current position is:');
                     //     // console.log('Latitude : ' + crd.latitude);
                     //     // console.log('Longitude: ' + crd.longitude);
                     //     // console.log('More or less ' + crd.accuracy + ' meters.');
                     // };
                     //
                     // function error(err) {
                     //     // console.warn('ERROR(' + err.code + '): ' + err.message);
                     // };
                     //
                     // window.navigator.geolocation.getCurrentPosition(success, error, options);
              AMap.plugin(["AMap.Geolocation"], function() {
                var geolocation = new AMap.Geolocation({});
                geolocation.getCityInfo(function (status, data) {
                  // console.log(arguments)
                })
                geolocation.getCurrentPosition(function (status, data) {
                  // console.log('data', arguments)
                  if (status === 'complete') {
                    // console.log('data', data)
                    setPointData(data)
                  }
                }, function (data2) {
                  // console.log(data2)
                })
              });
                // AMap.plugin('AMap.Geolocation', function () {
                //     var geolocation = new AMap.Geolocation({
                //         // 是否使用高精度定位，默认：true
                //         enableHighAccuracy: true,
                //         // 设置定位超时时间，默认：无穷大
                //         timeout: 20000,
                //         // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
                //         buttonOffset: new AMap.Pixel(10, 20),
                //         //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
                //         zoomToAccuracy: true,
                //         //  定位按钮的排放位置,  RB表示右下
                //         buttonPosition: 'RB'
                //     })
                //
                //     geolocation.getCurrentPosition()
                //     AMap.event.addListener(geolocation, 'complete', onComplete)
                //     AMap.event.addListener(geolocation, 'error', onError)
                //
                //     function onComplete(data) {
                //         // data是具体的定位信息
                //         // console.log('data', data)
                //         setPointData(data)
                //     }
                //
                //     function onError(data) {
                //         // 定位出错
                //         // console.log('data', data)
                //     }
                // })
            },
        },
        watch: {
          '$route': {
            deep: true,
            handler(val) {
              // console.log('val222', val)
            }
          }
        }
    }
</script>
<style lang="scss">
//.el-descriptions-item__cell{
//  width: 1px;
//}
.el-tooltip__popper{
  max-width: 500px;
}
    ::-webkit-scrollbar {
        width: 4px;
        height: 6px;
        background-color: #eee;
    }

    ::-webkit-scrollbar-track {
        background-color: #eee;
    }

    ::-webkit-scrollbar-thumb {
        background: $font_3;
    }

    ::-webkit-scrollbar-button:start {

        background-size: 8px 8px;
    }

    ::-webkit-scrollbar-button:end {
        background-size: 8px 8px;
    }

    body {
        margin: 0;
        min-height: 100vh;
        background: #fafbfc !important;
        font-family: MicrosoftYaHei, arial, helvetica, sans-serif;
    }

    #app {
        /*font-family: Avenir, Helvetica, Arial, sans-serif;*/

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }
</style>

<style lang="scss" scoped>
    #app {
        .el-header {
            /*box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);*/
            height: 44px !important;
            /*background-color: #409EFF;*/
            /*background-color: #3a8ee6;*/
            background-color: #2878FF;
        }
        ::v-deep .el-menu--horizontal>.el-menu-item{
            height: 44px;
            line-height: 44px;
        }
        .el-main {
            height: calc(100vh - 84px);
            //padding: 0 20px 20px;
            padding: 20px;
        }

        .el-footer {
            display: flex;
            justify-content: center;
            align-items: center;
            /*    background: white;
                 box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);*/
            height: 40px !important;
            color: #bbb;
            font-size: 12px;
        }
    }
</style>

